import React, { Component } from "react";
import classNames from "classnames";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import LiveMatrix from "../../../components/liveMatrix";
import LastView from "../../../components/lastView";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./underlyingchart.css";

let sortName = null, tagName = null

export default class UnderlyingChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastviewrics: null,
      selected: { label: null, ric: null },
      symbolsdata: null,
      comparisondata: null,
      comparisondataRW: null,
      isLoadingSymbol: true,
      isLoadingComparison: true,
    };
    this.page = "underlyingchart";
    this.initRedirect(props);
  }

  componentDidMount() {
    this.onFetchSymbol();
    document.title = "Underlying Charts | Singapore Warrants | Macquarie"
  }

  initRedirect(props) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
        }
      }
    }
  }

  initSelection(symbolsdata) {
    let lastselection = MQSGUtil.getLastSelection(this.page);
    if (this.ticker !== null) {
      lastselection = null;
    }
    let selectedsymbol = null;
    let initsymbol = null;
    let symbols = null;
    if ("symbols" in symbolsdata) {
      symbols = symbolsdata.symbols;
    }
    if (symbols !== null && Array.isArray(symbols)) {
      symbols.forEach((symbol) => {
        if ("ric" in symbol) {
          if (initsymbol === null) {
            initsymbol = symbol;
          }
          if (lastselection === symbol.ric) {
            selectedsymbol = symbol;
          }
          if (this.ticker == symbol.symbol.toUpperCase()) {
            selectedsymbol = symbol;
          }
        }
      });
    }
    if (selectedsymbol !== null) {
      initsymbol = selectedsymbol;
    }
    if (initsymbol !== null) {
      MQSGUtil.updateLastSelection(this.page, initsymbol.ric);
      const diplayname = MQSGUtil.getUnderlyingTickerDisplayName(
        initsymbol.ric,
        initsymbol.symbol,
        false
      );
      if (diplayname !== null) {
        const label = diplayname + " (" + initsymbol.symbol + ")";
        return { label, value: initsymbol };
      }
    }
    this.ticker = null;
    return null;
  }

  onFetchStaticSymbol() {
    const isLoadingSymbol = false;
    const staticData = MQSGUtil.getStaticData("UnderlyingChart");
    if ("symbols" in staticData) {
      const symbolsdata = staticData.symbols;
      this.setState({ symbolsdata, isLoadingSymbol });
      const initselected = this.initSelection(symbolsdata);
      this.onUnderlyingChange(initselected);
    }
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("UnderlyingChart")) {
      this.onFetchStaticSymbol();
      return;
    }

    const isLoadingSymbol = false;
    let symbolsdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?type=u";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result && Array.isArray(result.symbols)) {
            symbolsdata = result;
            const initselected = this.initSelection(symbolsdata);
            this.onUnderlyingChange(initselected);
          }
          this.setState({ symbolsdata, isLoadingSymbol });
        },
        (error) => {
          this.setState({ symbolsdata, isLoadingSymbol });
        }
      );
  }

  onFetchStaticComparisonData(ric) {
    const isLoadingComparison = false;
    const staticData = MQSGUtil.getStaticData("UnderlyingChart");
    let comparisondata = null;
    sortName = null;
    tagName = null;
    if (ric in staticData) {
      comparisondata = staticData[ric];
    }
    let getData = this.newData(comparisondata.comparison);
    this.setState({
      comparisondata,
      isLoadingComparison,
      comparisondataRW: getData
    });
  }

  onFetchComparisonData(ric) {
    if (MQSGUtil.isUsingStaticData("UnderlyingChart")) {
      setTimeout(() => {
        this.onFetchStaticComparisonData(ric);
      }, 100); // pretend fetch
      return;
    }

    const isLoadingComparison = false;
    let comparisondata = null;
    sortName = null;
    tagName = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/ComparisonDataJSON?type=u&ric=" + ric;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          comparisondata = result;
          let getData = this.newData(comparisondata.comparison);
          this.setState({
            comparisondata,
            isLoadingComparison,
            comparisondataRW: getData
          });
        },
        (error) => {
          let getData = this.newData(comparisondata.comparison);
          this.setState({
            comparisondata,
            isLoadingComparison,
            comparisondataRW: getData
          });
        }
      );
  }

  onUnderlyingChange(selected) {
    if ("value" in selected && "label" in selected) {
      const value = selected.value;
      if ("ric" in value && value.ric !== this.state.selected.ric) {
        const selectedNew = { ...this.state.selected };
        selectedNew["ric"] = value.ric;
        selectedNew["label"] = selected.label;
        sortName = null;
        tagName = null;
        this.setState({
          selected: selectedNew,
          comparisondata: null,
          isLoadingComparison: true,
          selectText: selected,
          comparisondataRW: null,
        });
        this.onFetchComparisonData(value.ric);
        MQSGUtil.updateLastSelection(this.page, value.ric);
        const lastviewrics = MQSGUtil.updateLastView(value.ric);
        this.setState({ lastviewrics });
        //调用子组件方法
        if (this.refs.childMeth) {
          this.refs.childMeth.getUnderlyingData(value.ric);
        }
      }
    }
  }
  // 删除第一条数据
  newData(data) {
    return data.filter((val, index, arr) => {
      return index !== 0;
    })
  }
  render() {
    return (
      <div id="underlying" className="pageobj">
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Underlying charts"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h2>Underlying charts</h2>
                </div>
                <p>
                  Our charts allow investors to perform technical analysis on
                  the warrants' underlying.
                </p>
                <Underlying
                  symbols={this.state.symbolsdata}
                  onUnderlyingChange={(selected) => {
                    this.onUnderlyingChange(selected);
                  }}
                />
                {/* summary
                                <GeelyBanner/>*/}
                <Summary
                  label={this.state.selected.label}
                  selected={this.state.selected}
                  comparisondata={this.state.comparisondata}
                  selectText={this.state.selectText}
                />
                <Chart ref="childMeth" />
                <RelatedWarrant
                  comparisondata={this.state.comparisondataRW}
                  onRelatedWarrantChg={(data) => {
                    this.setState({ comparisondataRW: data });
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <LastView lastviewrics={this.state.lastviewrics} />
              <LiveMatrix />
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Underlying extends React.Component {
  constructor(props) {
    super(props);
    this.underlyingOption = null;
  }

  onUnderlyingChange(selected) {
    window.location.href = '/tools/underlying/' + selected.value.symbol
    // this.props.onUnderlyingChange(selected);
  }

  getUnderlyingOption() {
    // if (this.underlyingOption === null) {
    if (this.props.symbols !== null) {
      if ("symbols" in this.props.symbols) {
        const symbolData = this.props.symbols.symbols;
        if (Array.isArray(symbolData)) {
          this.underlyingOption = [];
          symbolData.forEach((data) => {
            if ("symbol" in data) {
              const diplayname = MQSGUtil.getUnderlyingTickerDisplayName(
                data.ric,
                data.symbol,
                false
              );
              if (diplayname !== null) {
                const option = {};
                if (parseInt(data.ric)) {
                  option["label"] = diplayname + " (" + (data.ric).replace('.HK', '') + ")";
                } else {
                  option["label"] = diplayname + " (" + data.symbol + ")";
                }
                option["value"] = { ...data };
                this.underlyingOption.push(option);
              }
            }
          });
        }
      }
    }
    // }
    return this.underlyingOption;
  }

  render() {
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        textAlign: "center",
      }),
    };
    return (
      <div>
        <Select
          placeholder="Select underlying"
          options={this.getUnderlyingOption()}
          // styles={customStyles}
          styles={{
            option: (provided, state) => ({
              ...provided,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: '#FFFFFF',
              color: '#000',
              fontSize: '14px',
              ':hover': {
                backgroundColor: '#1A73E8',
              },
            }),
            valueContainer: (provided) => ({
              ...provided,
              justifyContent: 'center'
            })
          }}
          onChange={(selected) => this.onUnderlyingChange(selected)}
        />
      </div>
    );
  }
}

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: {
        '0027': {
          img: '/img/underlying/UnderlyingSG_GalaxyEnt.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0027'
        },
        '1024': {
          img: '/img/underlying/UnderlyingSG_Kuaishou.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1024'
        },
        '2318': {
          img: '/img/underlying/UnderlyingSG_PingAn.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/2318'
        },
        '1928': {
          img: '/img/underlying/UnderlyingSG_SandsChina.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1928'
        },
        '0968': {
          img: '/img/underlying/UnderlyingSG_Xinyi.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0968'
        },
        '1810': {
          img: '/img/underlying/xiaomiBanner.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1810'
        },
        '1772': {
          img: '/img/underlying/ganfeng.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1772'
        },
        '0700': {
          img: '/img/underlying/tencentBanner.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0700'
        },
        '1876': {
          img: '/img/underlying/budweiserBanner.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1876'
        },
        '6862': {
          img: '/img/underlying/haidilaoBanner.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/6862'
        },
        '9618': {
          img: '/img/underlying/JD.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/9618'
        },
        '9999': {
          img: '/img/underlying/NetEase.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/9999'
        },
        '9988': {
          img: '/img/underlying/BABA.png',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/9988'
        },
        '9888': {
          img: '',
          link: ''
        },
        '1211': {
          img: '/img/underlying/BYD.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1211'
        },
        '2382': {
          img: '/img/underlying/Sunny.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/2382'
        },
        '3690': {
          img: '/img/underlying/Meituan.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/3690'
        },
        '0175': {
          img: '/img/underlying/Geely.jpg',
          link: 'https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0175'
        }
      }
    }
  }
  render() {
    let summarydata = null;
    let summarydata2 = null;
    if (
      this.props.comparisondata !== null &&
      "comparison" in this.props.comparisondata
    ) {
      const dataarr = this.props.comparisondata.comparison;
      if (dataarr.length > 0) {
        summarydata = dataarr[0];
        summarydata2 = dataarr[1];
      }
    }
    const ticker = this.props.label === null ? "" : this.props.label;
    const bidval = MQSGUtil.getData(summarydata, "BID");
    const underlying_curr = MQSGUtil.getData(summarydata2, "underlying_curr");
    const bidchg = MQSGUtil.getNumber(summarydata, "NETCHNG_1");
    const bidchgString = MQSGUtil.getData(summarydata, "NETCHNG_1");
    const bidchgupdown = MQSGUtil.getUpDownClass(bidchg);
    const bidpchg = MQSGUtil.getNumber(summarydata, "PCTCHNG");
    const bidpchgString = MQSGUtil.getData(summarydata, "PCTCHNG") + "%";
    const bidpchgupdown = MQSGUtil.getUpDownClass(bidpchg);
    const bid = MQSGUtil.getData(summarydata, "BID");
    const ask = MQSGUtil.getData(summarydata, "ASK");
    const bidvol = MQSGUtil.getFormatKValue(summarydata, "BIDSIZE");
    const askvol = MQSGUtil.getFormatKValue(summarydata, "ASKSIZE");
    const trdvol = MQSGUtil.getData(summarydata, "ACVOL_1_SCALED");
    const updatetime = MQSGUtil.getData(summarydata, "update_time");
    var show = false;
    if (this.props.selectText) {
      if ("value" in this.props.selectText) {
        const diplayname = MQSGUtil.getUnderlyingTickerDisplayName(
          this.props.selectText.value.ric,
          this.props.selectText.value.symbol,
          false
        );
        if (diplayname !== null) {
          var option = '';
          if (this.props.selectText.value.ric.indexOf('.HK') != -1) {
            show = true;
            option = diplayname + " (" + this.props.selectText.value.ric.replace('.HK', '') + ")";
          } else {
            option = diplayname + " (" + this.props.selectText.value.symbol + ")";
          }
        }
      }
    }
    return (
        <div id="dwsummary" style={{ position: "relative", marginTop: '30px' }}>
          <div id="ticker" className="ricbar bgcolor-03">
            {option}
          </div>
          <table id="pricetbl" className="uppertbl">
            <colgroup>
              <col style={{ backgroundColor: "#E7E8E8", padding: "3px" }} />
              <col
                  style={{
                    backgroundColor: "#F5F6F7",
                    padding: "3px",
                    textAlign: "left",
                  }}
              />
              <col
                  style={{
                    backgroundColor: "#F5F6F7",
                    padding: "3px",
                    textAlign: "right",
                  }}
              />
            </colgroup>
            <tbody>
            <tr>
              <td className="uppertbl-col-bid" rowSpan="7" style={{ textAlign: 'left' }}>
                <div className="bidprice lbl">Last price ({underlying_curr})</div>
                <div id="bidval" className="bidprice val">
                  {bidval}
                </div>
              </td>
              <td className="uppertbl-col-chglbl">Change</td>
              <td id="bidchg" className={"uppertbl-col-chgval " + bidchgupdown}>
                {bidchgString}
              </td>
            </tr>
            <tr>
              <td className="uppertbl-col-pchglbl">%Change</td>
              <td
                  id="bidpchg"
                  className={"uppertbl-col-pchgval " + bidpchgupdown}
              >
                {bidpchgString}
              </td>
            </tr>
            <tr className="hidden-xs">
              <td className="uppertbl-col-bidasklbl">
                <span>Bid / Ask</span>
              </td>
              <td className="uppertbl-col-bidask val">
                <span className="bid">{bid}</span>
                <span>/</span>
                <span className="ask">{ask}</span>
              </td>
            </tr>
            <tr className="visible-xs">
              <td className="uppertbl-col-bidasklbl">
                <span>Bid</span>
              </td>
              <td className="uppertbl-col-bidask val visible-xs">
                <span className="bid">{bid}</span>
              </td>
            </tr>
            <tr className="visible-xs">
              <td className="uppertbl-col-bidasklbl">
                <span>Ask</span>
              </td>
              <td className="uppertbl-col-bidask val visible-xs">
                <span className="ask">{ask}</span>
              </td>
            </tr>
            <tr className="hidden-xs">
              <td className="uppertbl-col-bidaskvollbl">
                <span>Bid / Ask (Volume)</span>
              </td>
              <td className="uppertbl-col-bidaskvol val">
                <span className="bidvol">{bidvol}</span>
                <span>/</span>
                <span className="askvol">{askvol}</span>
              </td>
            </tr>
            <tr className="visible-xs">
              <td className="uppertbl-col-bidaskvollbl visible-xs">
                <span>Bid volume</span>
              </td>
              <td className="uppertbl-col-bidaskvol val visible-xs">
                <span className="bidvol">{bidvol}</span>
              </td>
            </tr>
            <tr className="visible-xs">
              <td className="uppertbl-col-bidaskvollbl visible-xs">
                <span>Ask volume</span>
              </td>
              <td className="uppertbl-col-bidaskvol val visible-xs">
                <span className="askvol">{askvol}</span>
              </td>
            </tr>
            <tr>
              <td className="uppertbl-col-pchglbl">Traded volume ('000)</td>
              <td id="trdvol" className="uppertbl-col-trdvol val">
                {trdvol}
              </td>
            </tr>
            </tbody>
          </table>
          <p className="small underlying_note">
            Last updated: <span id="lastupdate">{updatetime}</span>
          </p>
          <p className="small underlying_note">
            All market data is delayed 15 mins unless otherwise indicated by
            timestamp.
          </p>
        </div>
    );
  }
}

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateClicked: "D",
      timeClicked: "10",
      chartClicked: "ohlc",
      len1: 12,
      len2: 26,
      avg: 9,
      bolvalue: 20,
      rsiValue: 14,
      ric: "",
      first: '',
      isBol: false, //BOL开关
    };
  }

  dateClick = (val) => {
    this.setState(
      {
        dateClicked: val,
      },
      function () {
        this.getUnderlyingData();
      }
    );
  };

  timeClick = (val) => {
    this.setState(
      {
        timeClicked: val,
      },
      () => {
        this.getUnderlyingData();
      }
    );
  };

  chartClick = (val) => {
    this.setState(
      {
        chartClicked: val,
        first: 'val'
      },
      () => {
        this.getUnderlyingData();
      }
    );
  };
  isBolCheck = (e) => {
    this.setState({ isBol: e.target.checked }, () => {
      if (this.state.isBol) {
        this.getUnderlyingData();
      }
    });
  };
  changeBolValue = (e) => {
    this.setState({ bolvalue: e.target.value }, () => {
      if (this.state.isBol) {
        this.getUnderlyingData();
      }
    });
  };
  changeMacdValue = (type, e) => {
    var value = this.state[type];
    if (e.target.value != '' && !isNaN(e.target.value)) {
      value = Number(e.target.value)
    }
    this.setState({ [type]: value }, () => {
      this.getUnderlyingData();
    });
  };
  changeRsiValue = (e) => {
    this.setState({ rsiValue: e.target.value }, () => {
      this.getUnderlyingData();
    });
  };
  getUnderlyingData = (ric) => {
    var RIC;
    if (ric) {
      this.setState({ ric: ric });
      RIC = ric;
    } else {
      RIC = this.state.ric;
    }
    var that = this;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/GetUnderlyingData?period=" +
      this.state.dateClicked +
      "&ric=" +
      RIC;
    const URL =
      "https://t1www5.trkd-hs.com/thaidw_tLin/GetUnderlyingData?period=5M&ric=HSIc1&qid=1625571982843";
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        var data = result.data,
          ohlc = [],
          line = [],
          volume = [],
          //计算范围
          open = [],
          startVal = 100,
          endVal = 100;
        // + + 28800000
        for (var i = 0; i < data.length; i++) {
          if (this.state.dateClicked == 'D') {
            var timeVal =
              new Date(data[i]["time"]).getTime() + 28800000;
          } else {
            var timeVal =
              new Date(data[i]["time"]).getTime();
          }

          line.push([
            timeVal, // the date
            Number(data[i]["open"]), // open
            Number(data[i]["high"]), // high
            Number(data[i]["low"]), // low
            Number(data[i]["close"]), // close
          ]);
          volume.push([
            timeVal, // the date
            Number(data[i]["volume"]), // the volume
          ]);
          open.push(data[i]["open"]);
        }
        startVal = Math.min.apply(null, open) - startVal;
        endVal = endVal + Math.max.apply(null, open);
        var that = this;
        var types = '';
        if (that.state.first == '') {
          if (that.state.dateClicked == 'Y') {
            types = 'ohlc'
          } else {
            types = 'candlestick'
          }
        } else {
          types = that.state.chartClicked;
        }
        if (this.refs.chartID) {
          Highcharts.stockChart(this.refs.chartID, {
            rangeSelector: {
              enabled: false,
            },
            time: {
              //                        timezone:'Thailand',
              timezoneOffset: 0,
              useUTC: true,
            },
            chart: {
              marginRight: 30,
            },
            xAxis: {
              gridLineWidth: 1,
              lineWidth: 1,
              gridLineColor: "#A8A8A8",
              lineColor: "#A8A8A8",
              crosshair: {
                color: "#000",
              },
              type: "datetime",
              labels: {
                formatter: function () {
                  var date = '';
                  if (that.state.dateClicked == "D") {
                    date = Highcharts.dateFormat('%H:%M', this.value)
                  } else if (that.state.dateClicked == "W") {
                    date = Highcharts.dateFormat('%m/%d', this.value)
                  } else {
                    date = Highcharts.dateFormat('%y/%m', this.value)
                  }
                  return date
                }
              },
              dateTimeLabelFormats: {
                day: '%d/%m/%y',
              }
            },
            yAxis: [
              {
                height: "59%",
                resize: {
                  enabled: true,
                },
                lineWidth: 1,
                // offset: 40,
                gridLineColor: "#A8A8A8",
                lineColor: "#A8A8A8",
                labels: {
                  x: 30,
                },
              },
              {
                lineWidth: 1,
                height: "9%",
                top: "60%",
                gridLineColor: "#A8A8A8",
                lineColor: "#A8A8A8",
                labels: {
                  x: 30,
                },
              },
              {
                lineWidth: 1,
                height: "14%",
                top: "70%",
                gridLineColor: "#A8A8A8",
                lineColor: "#A8A8A8",
                labels: {
                  x: 30,
                },
              },
              {
                lineWidth: 1,
                height: "15%",
                top: "85%",
                gridLineColor: "#A8A8A8",
                lineColor: "#A8A8A8",
                labels: {
                  x: 30,
                },
              },
            ],
            plotOptions: {
              ohlc: {
                tooltip: {
                  pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                }
              },
              area: {
                tooltip: {
                  pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                },
                //设置图表透明度为 0.2
                fillOpacity: 0.2,
                //设置线段的宽度为 0
                lineWidth: 0,
                marker: {
                  enabled: false,
                  states: {
                    hover: {
                      enabled: true,
                      radius: 5,
                    },
                  },
                },
                shadow: false,
                states: {
                  hover: {
                    lineWidth: 1,
                  },
                },
                threshold: null,
              },
              candlestick: {
                tooltip: {
                  pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                }
              },
              series: {
                showInLegend: false,
                dataGrouping: {
                  units: [[
                    'millisecond', // unit name
                    [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                  ], [
                    'second',
                    [1, 2, 5, 10, 15, 30]
                  ], [
                    'minute',
                    [1]
                  ], [
                    'hour',
                    [1, 2, 3, 4, 6, 8, 12]
                  ], [
                    'day',
                    [1]
                  ], [
                    'week',
                    [1]
                  ], [
                    'month',
                    [1, 3, 6]
                  ], [
                    'year',
                    null
                  ]]
                }
              },
              column: {
                showInLegend: false,
              },
              scatter: {
                marker: {
                  symbol: "triangle-down",
                },
              },
            },
            tooltip: {
              // animation: true,
              // shape: "square",
              // headerShape: "callout",
              // borderWidth: 0,
              // backgroundColor: "#FECC0A",
              // shadow: false,
              // headerFormat: "",
              backgroundColor: '#fff',
              shape: 'square',
              headerShape: 'callout',
              borderWidth: 0,
              shadow: false,
              positioner: function (width, height, point) {
                var chart = this.chart,
                  position;
                if (point.isHeader) {
                  position = {
                    x: Math.max(
                      // Left side limit
                      chart.plotLeft,
                      Math.min(
                        point.plotX + chart.plotLeft - width / 2,
                        // Right side limit
                        chart.chartWidth - width - chart.marginRight
                      )
                    ),
                    y: point.plotY
                  };
                } else {
                  position = {
                    x: point.series.chart.plotLeft,
                    y: point.series.yAxis.top - chart.plotTop
                  };
                }
                return position;
              }
            },
            credits: { enabled: false }, // Close copyright notice
            navigator: { enabled: false }, //Turn off navigator
            scrollbar: { enabled: false }, //Close scrollbar
            series: [
              {
                // type: "ohlc",
                type: types,
                name: "AAPL",
                id: "aapl",
                color: 'red',
                lineColor: 'red',
                upColor: 'green',
                upLineColor: 'green',
                zIndex: 2,
                data: line,
                color: that.state.chartClicked == 'area' ? '#8B8CCB' : "#FF0909",

              },
              {
                type: "sma",
                zIndex: 1,
                linkedTo: "aapl",
                color: "#FECC69",
                marker: {
                  enabled: false,
                },
                params: {
                  index: 0,
                  period: that.state.timeClicked,
                },
                tooltip: {
                  pointFormat: '<b>Moving Average(MA)</b>  <span style="color:{series.color}">{point.y}</span>',
                }
              },
              {
                type: "column",
                name: "Volume",
                id: "volume",
                data: volume,
                yAxis: 1,
                color: "#8B8B8B",
                tooltip: {
                  pointFormat: 'Vol:{point.y}',
                  // {series.name}: 
                }
              },
              {
                yAxis: 2,
                type: "rsi",
                linkedTo: "aapl",
                color: "#FEDC56",
                marker: {
                  enabled: false,
                },
                params: {
                  index: 0,
                  period: that.state.rsiValue,
                },
                tooltip: {
                  pointFormat: '<b>Relative Strength Index(RSI)</b>  <span style="color:{series.color}">{point.y}</span>',
                }
              },
              {
                yAxis: 3,
                type: "macd",
                linkedTo: "aapl",
                color: "#F13232",
                params: {
                  shortPeriod: that.state.len1,
                  longPeriod: that.state.len2,
                  signalPeriod: that.state.avg,
                },
                marker: {
                  enabled: false,
                },
                tooltip: {
                  // pointFormat: '<b>Moving Average Convergence Divergence(MACD)</b>  <span style="color:{series.color}">{point.y}</span>',
                  // pointFormatter() {
                  // }
                }
              },
              {
                type: "bb",
                topLine: {
                  // 上轨线
                  styles: {
                    lineColor: "yellow",
                  },
                },
                bottomLine: {
                  // 下轨线
                  styles: {
                    lineColor: "purple",
                  },
                },
                color: "#FEDC56", //中轨颜色
                linkedTo: "aapl",
                //                                        data: ohlc,
                visible: that.state.isBol,
                name: "BOL",
                tooltip: {
                  pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                    "Upper Band：{point.top}<br/>" +
                    "Middle Band：{point.middle}<br/>" +
                    "Lower Band：{point.bottom}<br/>",
                },
                params: {
                  period: that.state.bolvalue,
                },
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: "100%",
                  },
                  chartOptions: {
                    rangeSelector: {
                      inputEnabled: false,
                    },
                  },
                },
              ],
            },
          });
        }
      });
  };
  render() {
    return (
      <div>
        <table id="periodtab" className="ctab">
          <tbody>
            <tr>
              <td
                className={classNames(
                  { active: this.state.dateClicked == "D" },
                  "ctab-item"
                )}
                onClick={() => this.dateClick("D")}
              >
                1D
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.state.dateClicked == "W" },
                  "ctab-item"
                )}
                onClick={() => this.dateClick("W")}
              >
                1W
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.state.dateClicked == "M" },
                  "ctab-item"
                )}
                onClick={() => this.dateClick("M")}
              >
                1M
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.state.dateClicked == "Y" },
                  "ctab-item"
                )}
                onClick={() => this.dateClick("Y")}
              >
                MAX
              </td>
            </tr>
          </tbody>
        </table>
        <div id="chartbox" style={{ width: "100%" }}>
          <div className="chartFrame" style={{ height: "750px" }}>
            <div
              id="chartID"
              ref="chartID"
              style={{ width: "100%", height: "700px" }}
            ></div>
          </div>
          <div className="ta_panel">
            <div>
              <div className="row">
                <div className="col-sm-4">
                  <span
                    data-hasqtip="0"
                    data-tip
                    data-for="Ma-tooltip"
                  >
                    Moving Average (MA)
                  </span>
                  <ReactTooltip
                    id="Ma-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Moving Average (MA): </b>
                    MAs are often used to determine trend direction. If the MA is moving up, the trend is up. If the MA is moving down, the trend is down.
                  </ReactTooltip>
                </div>
                <div className="col-sm-8">
                  <table className="MAtab ctab">
                    <tbody>
                      <tr>
                        <td
                          className={classNames(
                            { active: this.state.timeClicked == "10" },
                            "ctab-item"
                          )}
                          onClick={() => this.timeClick("10")}
                        >
                          10D
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            { active: this.state.timeClicked == "20" },
                            "ctab-item"
                          )}
                          onClick={() => this.timeClick("20")}
                        >
                          20D
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            { active: this.state.timeClicked == "50" },
                            "ctab-item"
                          )}
                          onClick={() => this.timeClick("50")}
                        >
                          50D
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            { active: this.state.timeClicked == "100" },
                            "ctab-item"
                          )}
                          onClick={() => this.timeClick("100")}
                        >
                          100D
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            { active: this.state.timeClicked == "200" },
                            "ctab-item"
                          )}
                          onClick={() => this.timeClick("200")}
                        >
                          200D
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Chart type</div>
                <div className="col-sm-8">
                  <table className="typetab ctab">
                    <tbody>
                      <tr>
                        <td
                          className={classNames(
                            { active: this.state.chartClicked === "ohlc" },
                            "ctab-item"
                          )}
                          onClick={() => this.chartClick("ohlc")}
                        >
                          <img src="/img/bullet/bar.png" alt="" />
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            { active: this.state.chartClicked === "area" },
                            "ctab-item"
                          )}
                          onClick={() => this.chartClick("area")}
                        >
                          <img src="/img/bullet/line.png" alt="" />
                        </td>
                        <td className="ctab-item-space">&nbsp;</td>
                        <td
                          className={classNames(
                            {
                              active: this.state.chartClicked === "candlestick",
                            },
                            "ctab-item"
                          )}
                          onClick={() => this.chartClick("candlestick")}
                        >
                          <img src="/img/bullet/candle.png" alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-sm-4">
                  <span
                    data-hasqtip="0"
                    data-tip
                    data-for="RSI-tooltip"
                  >
                    Relative Strength Index (RSI)
                  </span>
                  <ReactTooltip
                    id="RSI-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Relative Strength Index (RSI): </b>
                    Momentum oscillator that measures the speed and change of price movements. RSI is considered overbought when above 70 and oversold when below 30. These traditional levels can also be adjusted if necessary to better fit the security.  In a bull market, the RSI tends to remain in the 40 to 90 range with the 40-50 zone acting as support. During a bear market, the RSI tends to stay between the 10 to 60 range with the 50-60 zone acting as resistance.
                    Note: If underlying prices make a new high or low that isn't confirmed by the RSI, this divergence can signal a price reversal.
                  </ReactTooltip>
                </div>
                <div className="col-sm-8">
                  <input
                    id="rsiValue"
                    className="txtboxs ui-autocomplete-input"
                    autoComplete="off"
                    role="textbox"
                    aria-autocomplete="list"
                    aria-haspopup="true"
                    data-original-title=""
                    defaultValue={14}
                    onChange={this.changeRsiValue}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">

                  <span
                    data-hasqtip="0"
                    data-tip
                    data-for="MACD-tooltip"
                  >
                    Moving Average Convergence
                    <br />
                    Divergence (MACD)
                  </span>
                  <ReactTooltip
                    id="MACD-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Moving Average Convergence Divergence (MACD): </b>
                    MACD crossing above zero is considered bullish, while crossing below zero is bearish.
                  </ReactTooltip>
                </div>
                <div className="col-sm-8">
                  <table className="txttbl">
                    <tbody>
                      <tr>
                        <td className="txttbl_lbl">Len 1</td>
                        <td className="txttbl_input">
                          <input
                            id="macdLen1"
                            title=""
                            placeholder=""
                            className="macdValue txtboxs ui-autocomplete-input"
                            autoComplete="off"
                            role="textbox"
                            aria-autocomplete="list"
                            aria-haspopup="true"
                            data-original-title=""
                            defaultValue={12}
                            onChange={(e) => this.changeMacdValue("len1", e)}
                          />
                        </td>
                        <td className="txttbl_lbl">Len 2</td>
                        <td className="txttbl_input">
                          <input
                            id="macdLen2"
                            title=""
                            placeholder=""
                            className="macdValue txtboxs ui-autocomplete-input"
                            autoComplete="off"
                            role="textbox"
                            aria-autocomplete="list"
                            aria-haspopup="true"
                            data-original-title=""
                            defaultValue={26}
                            onChange={(e) => this.changeMacdValue("len2", e)}
                          />
                        </td>
                        <td className="txttbl_lbl">Avg</td>
                        <td className="txttbl_input last">
                          <input
                            id="macdAvg"
                            title=""
                            placeholder=""
                            className="macdValue txtboxs ui-autocomplete-input "
                            autoComplete="off"
                            role="textbox"
                            aria-autocomplete="list"
                            aria-haspopup="true"
                            data-original-title=""
                            defaultValue={9}
                            onChange={(e) => this.changeMacdValue("avg", e)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">



                  <span
                    data-hasqtip="0"
                    data-tip
                    data-for="BOL-tooltip"
                  >
                    Bollinger (BOL)
                  </span>
                  <ReactTooltip
                    id="BOL-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Bollinger (BOL): </b>
                    When the price of the asset breaks below the lower band of the Bollinger Bands, prices have perhaps fallen too much and are due to bounce. On the other hand, when price breaks above the upper band, the market is perhaps overbought and due for a pullback.
                  </ReactTooltip>

                </div>
                <div className="col-sm-8">
                  <div className="input-group txtcheckbox">
                    <input
                      id="bollingerValue"
                      min="0"
                      max="100"
                      title=""
                      placeholder=""
                      className="txtboxs ui-autocomplete-input"
                      autoComplete="off"
                      role="textbox"
                      aria-autocomplete="list"
                      aria-haspopup="true"
                      data-original-title=""
                      defaultValue={20}
                      onChange={this.changeBolValue}
                    />
                    {/* readOnly */}
                    <input
                      id="isBollingerCheckBox"
                      className="checkbox"
                      type="checkbox"
                      onChange={this.isBolCheck}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class RelatedWarrant extends Component {
  getData(data, fieldName) {
    if (fieldName in data) {
      return data[fieldName];
    }
    return "";
  }

  getRows(getData) {
    const rows = [];
    const fields = [
      "ticker",
      "type",
      "issuer_code",
      "exercise_price",
      "last_trading_date",
      "effective_gearing",
    ];
    if (this.props.comparisondata !== null) {
      let dataarr = "";
      if (typeof (getData) == "undefined") {
        dataarr = this.props.comparisondata;
      } else {
        dataarr = getData;
      }
      dataarr.forEach((data, index) => {
        const columns = [];
        fields.forEach((field, dataIndex) => {
          const tdIndex = index + "_" + dataIndex;
          const value = this.getData(data, field);
          let column = <td key={tdIndex}>{value}</td>;
          if (field === "ticker") {
            if (value !== "") {
              column = (
                <td key={tdIndex}>
                  <a href={"/tools/livematrix/" + value}>{value}</a>
                </td>
              );
            }
          }
          columns.push(column);
        });
        rows.push(
          <tr className="rowtmpl" key={index}>
            {columns}
          </tr>
        );
      });
    }
    return rows;
  }
  //字母排序
  sortLetter(data, key, tag) {
    if (tag == "asc") {
      //升序
      data.sort(function (a, b) {
        return (a[key] + '').localeCompare(b[key] + '');
      })
      return data;
    }
    //降序
    data.sort(function (a, b) {
      return (b[key] + '').localeCompare(a[key] + '');
    })
    return data;
  }
  //数字排序
  sortNumber(data, key, tag) {
    if (tag == "asc") {
      //升序
      return data.sort(function (a, b) {
        let x = Number(a[key]);
        let y = Number(b[key]);
        return x < y ? -1 : x > y ? 1 : 0;
      })
    }
    //降序
    return data.sort(function (a, b) {
      let x = Number(a[key]);
      let y = Number(b[key]);
      return x < y ? 1 : x > y ? -1 : 0;
    })
  }
  // 将年月日转换为时间戳
  chgFormatDate(val) {
    let monData = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' }
    let getD = new Date();
    let getY = String(getD.getFullYear());
    getY = getY.slice(0, 2);
    if (val && val !== "-" && typeof (val) !== "undefined" && val != 0) {
      let arr = val.split(" ");
      let newDate = getY + arr[2] + '-' + monData[arr[1]] + '-' + arr[0];
      return Date.parse(newDate);
    }
    return '-'
  }
  // 点击排序
  sortClick(keyName, tagsName) {
    if (this.props.comparisondata !== null) {
      if (sortName == keyName && tagName == tagsName) return
      sortName = keyName;
      tagName = tagsName;
      let getData = "", hasData = [], noData = [];
      let keyData = ["ticker", "type", "issuer_code"];
      let dataarr = this.props.comparisondata;
      for (let i in dataarr) {
        let tempsData = dataarr[i][keyName]
        tempsData == '-' || typeof (tempsData) == 'undefined' ? noData.push(dataarr[i]) : hasData.push(dataarr[i])
        dataarr[i]["last_trading_date_temp"] = this.chgFormatDate(dataarr[i]["last_trading_date"]);
      }
      if (keyData.includes(keyName)) {
        getData = this.sortLetter(hasData, keyName, tagsName);
      } else {
        getData = this.sortNumber(hasData, keyName, tagsName);
      }
      getData.push.apply(getData, noData)
      this.props.onRelatedWarrantChg(getData);
    }
  }
  render() {
    return (
      <div id="dwtable">
        <h3>Related warrants</h3>
        <table className="table table-striped table-noHeaderborder">
          <thead>
            <tr className="htxt">
              <th sortby="sl">Warrant code</th>
              <th sortby="type">Type</th>
              <th sortby="isr">Issuer</th>
              <th sortby="ep">Strike</th>
              <th sortby="ltd">Last trading date</th>
              <th sortby="eg">
                Effective gearing
                <br />
                (x)
              </th>
            </tr>
            <tr className="sort hidden-sm hidden-xs">
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="sl"
                  onClick={() => this.sortClick("ticker", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="sl"
                  onClick={() => this.sortClick("ticker", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="type"
                  onClick={() => this.sortClick("type", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="type"
                  onClick={() => this.sortClick("type", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="isr"
                  onClick={() => this.sortClick("issuer_code", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="isr"
                  onClick={() => this.sortClick("issuer_code", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="ep"
                  onClick={() => this.sortClick("exercise_price", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="ep"
                  onClick={() => this.sortClick("exercise_price", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="ltd"
                  onClick={() => this.sortClick("last_trading_date_temp", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="ltd"
                  onClick={() => this.sortClick("last_trading_date_temp", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="eg"
                  onClick={() => this.sortClick("effective_gearing", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="eg"
                  onClick={() => this.sortClick("effective_gearing", "asc")}
                ></span>
              </th>
            </tr>
            <tr className="sort visible-sm visible-xs">
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="sl"
                  onClick={() => this.sortClick("ticker", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="sl"
                  onClick={() => this.sortClick("ticker", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="type"
                  onClick={() => this.sortClick("type", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="type"
                  onClick={() => this.sortClick("type", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="isr"
                  onClick={() => this.sortClick("issuer_code", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="isr"
                  onClick={() => this.sortClick("issuer_code", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="ep"
                  onClick={() => this.sortClick("exercise_price", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="ep"
                  onClick={() => this.sortClick("exercise_price", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="ltd"
                  onClick={() => this.sortClick("last_trading_date_temp", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="ltd"
                  onClick={() => this.sortClick("last_trading_date_temp", "asc")}
                ></span>
              </th>
              <th>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-bottom"
                  sortorder="desc"
                  sortby="eg"
                  onClick={() => this.sortClick("effective_gearing", "desc")}
                ></span>
                <span
                  className="icon-sort glyphicon glyphicon-triangle-top"
                  sortorder="asc"
                  sortby="eg"
                  onClick={() => this.sortClick("effective_gearing", "asc")}
                ></span>
              </th>
            </tr>
          </thead>
          <tbody>{this.getRows()}</tbody>
        </table>
        <p style={{ fontSize: "12px", fontWeight: "normal" }}>
          Note that the underlying used for HSI 1D charts is the HSI futures,
          while 1W, 1M and Max charts are based on the HSI cash index.
        </p>
        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
      </div>
    );
  }
}
class GeelyBanner extends Component {
  render() {
    return (
      <div id="dwsummary" style={{ position: "relative" }}>
        <div
          id="ticker"
          className="ricbar bgcolor-03"
          style={{ marginBottom: "0" }}
        >
          GEELY (GEELY AUTO)
        </div>
        <div className="GeelyBanner" style={{ paddingBottom: "20px" }}>
          <a
            href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0175"
            target="_blank"
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src="/img/underlying/Geely.jpg"
            />
          </a>
        </div>
      </div>
    );
  }
}
// Summary > Image e.g 9988.HK
