import React, { Component } from "react";
import classNames from "classnames";
import LiveMatrix from "../../../components/liveMatrix";
import MoneyFlow from "../../../components/moneyFlow";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./topgainer.css";

export default class TopGainer extends Component {
  constructor() {
    super();
    this.state = {
      rankingdata: null,
      tab: "gainer",
      date: '',
      timeValue: '0'
    };
  }

  componentDidMount() {
    this.onFetchData();
    document.title = "Top gainers / losers | Singapore Warrants | Macquarie"
  }

  // onFetchStaticData() {
  //   const staticData = MQSGUtil.getStaticData("TopGainer");
  //   if (staticData !== null) {
  //     this.setState({ rankingdata: staticData });
  //   }
  // }

  onFetchData() {
    // if (MQSGUtil.isUsingStaticData("TopGainer")) {
    //   setTimeout(() => {
    //     this.onFetchStaticData();
    //   }, 100); // pretend fetch
    //   return;
    // }
    let rankingdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=ranking";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          rankingdata = result;
          var monthEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"];
          var date = new Date()
          var dateStr = date.getDate() + ' ' + monthEnglish[date.getMonth()] + " " + date.getFullYear() + " " + date.getHours() + ':' + date.getMinutes()
          this.setState({ rankingdata, date: dateStr });
        },
        (error) => {
          this.setState({ rankingdata });
        }
      );
  }

  tabChange(tab) {
    this.setState({ tab });
  }
  dateChange(tab) {
    this.setState({ timeValue: tab })
    let rankingdata = null;
    if (tab == 0) {
      var url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=ranking";
    } else {
      var url = MQSGUtil.getAPIBasePath() + `/GetGainersAndLosers?week=${tab}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          rankingdata = result;
          var monthEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"];
          var date = new Date()
          var dateStr = date.getDate() + ' ' + monthEnglish[date.getMonth()] + " " + date.getFullYear() + " " + date.getHours() + ':' + date.getMinutes()
          this.setState({ rankingdata, date: dateStr });
        },
        (error) => {
          this.setState({ rankingdata });
        }
      );
  }
  render() {
    return (
      <div id="toptradewarrant" className="pageobj">
        <BreadcrumbBar
          link="/home/warrantstats"
          group="Warrant statistics"
          title="Top 10 gainers / losers"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg hidden-sm hidden-xs">
            <img src="/img/bg/top-toptengl.jpg" alt="" />
          </div>
          <div className="row">
            <div className="col-md-8" style={{ paddingLeft: 0 }}>
              <div className="section">
                <div className="page-header">
                  <h2>Top 10 gainers / losers</h2>
                </div>
                <p>
                  shows the warrants with the biggest percentage price change at
                  the end of each day.
                </p>
                <div className="tablist">
                  <div id="topTabs" className="tablist-left">
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.tab === "gainer",
                            })}
                            onClick={() => this.tabChange("gainer")}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Top 10 gainers
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tab === "loser",
                            })}
                            onClick={() => this.tabChange("loser")}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Top 10 losers
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="indicator hidden-xs hidden-sm">
                  <ul className="indicator-list list-unstyled list-inline">
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-liquidity indicator-item-icon"></span>
                        <span className="ttip" data-target="highestliquidity">
                          Highest liquidity
                        </span>
                      </div>
                    </li>
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon"></span>
                        <span className="ttip" data-target="nearexpiry">
                          Near expiry
                        </span>
                      </div>
                    </li>

                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon"></span>
                        <span className="ttip" data-target="responsive">
                          Responsive
                        </span>
                      </div>
                    </li>
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon"></span>
                        <span className="ttip" data-target="hotdw">
                          Trending warrants
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="tablist">
                  <div id="topTabs" className="tablist-left">
                    <table style={{ margin: '0 auto' }}>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.timeValue === "0",
                            })}
                            onClick={() => this.dateChange("0")}
                          >
                            <div className="tablist-items">
                              <div className="tablist-item-txt">
                                Today
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.timeValue === "1",
                            })}
                            onClick={() => this.dateChange("1")}
                          >
                            <div className="tablist-items">
                              <div className="tablist-item-txt">
                                1W
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.timeValue === "2",
                            })}
                            onClick={() => this.dateChange("2")}
                          >
                            <div className="tablist-items">
                              <div className="tablist-item-txt">
                                2W
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.timeValue === "3",
                            })}
                            onClick={() => this.dateChange("3")}
                          >
                            <div className="tablist-items">
                              <div className="tablist-item-txt">
                                3W
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.timeValue === "4",
                            })}
                            onClick={() => this.dateChange("4")}
                          >
                            <div className="tablist-items">
                              <div className="tablist-item-txt">
                                4W
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* 2tab */}
                <div className="tabcontent-holder" style={{ overflowX: "scroll" }}>
                  <Top10
                    rankingdata={this.state.rankingdata}
                    tab={this.state.tab}
                  />
                </div>
                <div className="indicator visible-sm visible-xs">
                  <ul className="indicator-list list-unstyled list-inline">
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-liquidity indicator-item-icon"></span>
                        <span className="ttip" data-target="highestliquidity">
                          Highest liquidity
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon"></span>
                        <span className="ttip" data-target="nearexpiry">
                          Near expiry
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon"></span>
                        <span className="ttip" data-target="responsive">
                          Responsive
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon"></span>
                        <span className="ttip" data-target="hotdw">
                          Trending warrants
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <p className="small">
                  Last updated: {this.state.date}

                  <br />
                  <br />
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4" style={{ paddingRight: 0 }}>
              <LiveMatrix />
              {/* <MoneyFlow /> */}
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Top10 extends Component {
  getRows() {
    const rows = [];
    const tab = this.props.tab;
    const topdata = MQSGUtil.getData(this.props.rankingdata, tab, null);
    if (topdata !== null && Array.isArray(topdata)) {
      topdata.forEach((data, index) => {
        const ticker = MQSGUtil.getData(data, "ticker");
        const indicatorclassname = MQSGUtil.getIndicatorClassName(data);
        const indicatorclassnamespan = indicatorclassname.map((classname) => {
          return (
            <span
              className={"indicator-item-icon " + classname}
              key={classname}
            ></span>
          );
        });
        const underlyingticker = MQSGUtil.getData(data, "underlying_ticker");
        const underlyingric = MQSGUtil.getData(data, "underlying_ric");
        const underlyingtickerdisplayname =
          MQSGUtil.getUnderlyingTickerDisplayName(underlyingric, underlyingticker);
        const issuercode = MQSGUtil.getData(data, "issuer_code");
        const type = MQSGUtil.getData(data, "type");
        const bid = MQSGUtil.getData(data, "BID");
        const pctchng = MQSGUtil.getData(data, "BID_PCTCHNG");
        const pctchngString = MQSGUtil.signedString(pctchng, "", "");
        const pctchngupdown = MQSGUtil.getUpDownClass(pctchng);
        const underlyingpctchng = MQSGUtil.getData(data, "underlying_pctchng");
        const underlyingpctchngString = MQSGUtil.signedString(
          underlyingpctchng,
          "",
          ""
        );
        const underlyingpctchngupdown =
          MQSGUtil.getUpDownClass(underlyingpctchng);

        const acvol1 = MQSGUtil.getNumber(data, "ACVOL_1", "", 0, 1000, true);

        const column1 = (
          <td className="col ticker">
            <a href={"/tools/livematrix/" + ticker}>{ticker}</a>
          </td>
        );
        const column2 = (
          <td className="col indicator">{indicatorclassnamespan}</td>
        );
        const column3 = (
          <td className="col underlying_name hidden-xs">
            <a href={"/tools/underlying/" + underlyingticker}>
              {underlyingtickerdisplayname}
            </a>
          </td>
        );
        const column4 = (
          <td className="col issuer_code hidden-xs">{issuercode}</td>
        );
        const column5 = <td className="col type">{type}</td>;
        const column6 = <td className="col BID">{bid}</td>;
        const column7 = (
          <td className={"col BID_PCTCHNG hidden-xs " + pctchngupdown}>
            {pctchngString}
          </td>
        );
        const column8 = (
          <td className={"col underlying_pctchng " + underlyingpctchngupdown}>
            {underlyingpctchngString}
          </td>
        );
        const column9 = <td className="col ACVOL_1">{acvol1}</td>;
        const row = (
          <tr className="rowtmpl" key={this.props.tab + "_" + index}>
            {column1}
            {column2}
            {column3}
            {column4}
            {column5}
            {column6}
            {column7}
            {column8}
            {/* {column9} */}
          </tr>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <div id="topTbl">
        <table className="table table-striped table-closed">
          <thead>
            <tr className="rowtmpl_h">
              <th>Warrant code</th>
              <th></th>
              <th className="hidden-xs">Underlying</th>
              <th className="hidden-xs">Issuer</th>
              <th>Type</th>
              <th>
                Bid
                <br />
                (SGD)
              </th>
              <th className="hidden-xs">
                Change
                <br />
                (%)
              </th>
              <th>
                Underlying
                <br />
                change (%)
              </th>
              {/* <th>
                Traded
                <br />
                volume('000)
              </th> */}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}
