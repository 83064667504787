import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container-fluid">
          <div className="sitemap sitemaps hidden-xs hidden-sm">
            <div><Link style={{
              color: '#fff', padding: '0px',
              margin: '0px',
              fontSize: '25px',
              paddingLeft: '25px'
            }} to="/Sitemap">Sitemap</Link></div>
            <div className="sitemap-col">
              <h3 className="color-primary">Warrant tools</h3>
              <ul>
                <li>
                  <Link to="/tools/livematrix">Live matrix</Link>
                </li>
                <li>
                  <Link to="/tools/selector">Warrant selector</Link>
                </li>
                <li>
                  <Link to="/tools/warrantsearch">Warrant search</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/tools/HistoricalPerformance", query: { name: 'Performance' } }}>Historical Performance</Link>
                </li>
                <li>
                  <Link to="/tools/warrantterms">Warrant terms</Link>
                </li>
                <li>
                  <Link to="/tools/warrantcalculator">
                    Warrant calculator
                  </Link>
                </li>
                <li>
                  <Link to="/tools/underlying">Underlying charts</Link>
                </li>
                <li>
                  <Link to="/tools/warrantcomparison">
                    Warrant comparison
                  </Link>
                </li>
                <li>
                  <Link to="/tools/document">Warrant documents</Link>
                </li>

              </ul>
            </div>
            <div className="sitemap-col clearfix">
              <h3 className="color-primary">Market news</h3>
              <ul>
                {/* <li>
                  <Link to="/telegram">Join us on Telegram</Link>
                </li> */}
                <li>
                  <Link to="/marketnews/dailyhighlights">
                    Morning Market Buzz
                  </Link>
                </li>
                <li>
                  <Link to="/marketnews/overnightmarkets">
                    Overnight market wrap
                  </Link>
                </li>
                <li>
                  <Link to="/marketnews/subscribe">Subscribe</Link>
                </li>
                <li>
                  <Link to="/marketnews/photogallery">Photo gallery</Link>
                </li>
              </ul>
            </div>
            <div className="sitemap-col">
              <h3 className="color-primary">Warrant education</h3>
              <ul>
                {/*<div
                  className=""
                  style={{
                    margin: "0",
                    padding: "0",
                    borderBottom: "none",
                    fontSize: "14px",
                    color: "#fff",
                    paddingBottom: "4px",
                    fontFamily: "HelveticaNeueMedium",
                  }}
                >
                  <div
                    className=""
                    data-toggle="collapse"
                    data-parent="#navmbl-accordion"
                    data-target="#mblmenu12"
                    href="#mblmenu6"
                    aria-expanded="true"
                    style={{ cursor: "pointer" }}
                  >
                    <li>
                      New to warrants
                      <span
                        className="navmbl-panel-caret glyphicon glyphicon-menu-down"
                        style={{ paddingTop: "4px" }}
                      ></span>
                    </li>
                  </div>
                  <div
                    id="mblmenu12"
                    className="navmbl-panel-collapse-mdlg collapse"
                    style={{ margin: "0px", fontWeight: "400" }}
                  >
                    <li>
                      <Link to="/education/getstarted">
                        How to get started
                      </Link>
                    </li>
                    <li>
                      <a href="/education/warrantvideos">
                        Educational videos
                      </a>
                    </li>
                    <li>
                      <Link to="/education/brochure">Brochure</Link>
                    </li>
                    <li>
                      <Link to="/SuccessSpeaks">Success Speaks</Link>
                    </li>
                    <li>
                      <a target="_blank" href="/edu/investormy.html">
                        Investor academy
                      </a>
                    </li>
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    margin: "0",
                    padding: "0",
                    borderBottom: "none",
                    fontSize: "14px",
                    color: "#fff",
                    paddingBottom: "4px",
                    fontFamily: "HelveticaNeueMedium",
                  }}
                >
                  <div
                    className=""
                    data-toggle="collapse"
                    data-parent="#navmbl-accordion"
                    data-target="#mblmenu13"
                    href="#mblmenu6"
                    aria-expanded="true"
                    style={{ cursor: "pointer" }}
                  >
                    <li>
                      Existing warrant investors
                      <span
                        className="navmbl-panel-caret glyphicon glyphicon-menu-down"
                        style={{ paddingTop: "4px" }}
                      ></span>
                    </li>
                  </div>
                  <div
                    id="mblmenu13"
                    className="navmbl-panel-collapse-mdlg collapse"
                    style={{ margin: "0px", fontWeight: "400" }}
                  >
                    <li>
                      <a href="/education/marketview">
                        Market View / Commentary
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/edu/investormy.html">
                        Investor academy
                      </a>
                    </li>
                  </div>
                </div> */}

                {/* /////////////////////// */}
                <li>
                  <Link to="/LandingPage">New to warrants / website</Link>
                </li>
                <li>
                  <Link to="/education/getstarted">How to get started</Link>
                </li>
                <li>
                  <Link to="/education/brochure">Brochure</Link>
                </li>
                <li>
                  <a href="/education/ShortFormVideos">Educational 1-minute videos</a>
                </li>
                <li>
                  <a href="/education/warrantvideos">All videos</a>
                </li>
                <li>
                  <Link to="/SuccessSpeaks">Success Speaks</Link>
                </li>
                <li>
                  <a target="_blank" href="/WarrantsGuidebook">
                    Warrants guidebook
                  </a>
                </li>
                <li>
                  <Link to="/education/whymacquarie">Why Macquarie</Link>
                </li>
                <li>
                  <Link to="/education/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/education/seminar">Seminar</Link>
                </li>
                <li>
                  <Link to="/education/SiMSCI">SiMSCI</Link>
                </li>
                <li>
                  <Link to="/education/Indexwarrants">Index Warrants</Link>
                </li>
                {/* <li>
                  <Link to="/education/hstech">HSTECH</Link>
                </li> */}
              </ul>
            </div>
            <div className="sitemap-col">
              <h3 className="color-primary">Warrant statistics</h3>
              <ul>
                <li>
                  <Link to="/marketdata/toptradedwarrants">
                    Top traded warrants
                  </Link>
                </li>
                <li>
                  <a href="/marketdata/topgainloss">
                    Top 10 gainers / losers
                  </a>
                </li>
                <li>
                  <a href="/marketdata/turnover">Warrant market turnover</a>
                </li>
                <li>
                  <a href="/marketdata/turnoverdist">
                    Turnover distribution
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
          <div className="sitemap-xs sitemaps visible-xs visible-sm">
            <div><Link style={{
              color: '#fff', padding: '0px',
              margin: '0px',
              fontSize: '18px',
              paddingLeft: '5px'
            }} to="/Sitemap">Sitemap</Link></div>
            <div className="sitemap-row">
              <div className="sitemap-accordion">
                <div className="sitemap-panel">
                  <div className="sitemap-panel-heading">
                    <div className="sitemap-panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent=".sitemap-accordion"
                        href="#sm1"
                      >
                        <span className="glyphicon glyphicon-menu-down" style={{ marginRight: 5 }}></span>
                        Warrant tools
                      </a>
                    </div>
                  </div>
                  <div id="sm1" className="sitemap-panel-collapse collapse">
                    <div className="sitemap-panel-body">
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/livematrix">Live matrix</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/selector">Warrant selector</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/warrantsearch">Warrant search</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        {/* <Link to="/tools/document">Warrant documents</Link> */}
                        <Link to={{ pathname: "/tools/HistoricalPerformance", query: { name: 'Performance' } }}>Historical Performance</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/warrantterms">Warrant terms</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/warrantcalculator">
                          Warrant calculator
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/underlying">Underlying charts</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/warrantcomparison">
                          Warrant comparison
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/tools/document">Warrant documents</Link>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sitemap-row">
              <div className="sitemap-accordion">
                <div className="sitemap-panel">
                  <div className="sitemap-panel-heading">
                    <div className="sitemap-panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent=".sitemap-accordion"
                        href="#sm4"
                      >
                        <span className="glyphicon glyphicon-menu-down" style={{ marginRight: 5 }}></span>
                        Market news
                      </a>
                    </div>
                  </div>
                  <div id="sm4" className="sitemap-panel-collapse collapse">
                    <div className="sitemap-panel-body">
                      {/* <div className="sitemap-row sitemap-panel">
                        <Link to="/telegram">Join us on Telegram</Link>
                      </div> */}
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/marketnews/dailyhighlights">
                          Morning Market Buzz
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/marketnews/overnightmarkets">
                          Overnight market wrap
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/marketnews/subscribe">Subscribe</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/marketnews/photogallery">
                          Photo gallery
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sitemap-row">
              <div className="sitemap-accordion">
                <div className="sitemap-panel">
                  <div className="sitemap-panel-heading">
                    <div className="sitemap-panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent=".sitemap-accordion"
                        href="#sm3"
                      >
                        <span className="glyphicon glyphicon-menu-down" style={{ marginRight: 5 }}></span>
                        Warrant education
                      </a>
                    </div>
                  </div>
                  <div id="sm3" className="sitemap-panel-collapse collapse">
                    <div className="sitemap-panel-body">
                      {/* <div className="dropmenu-row">
                        <div
                          className="navmbl-panel-heading"
                          data-toggle="collapse"
                          data-parent="#navmbl-accordion"
                          data-target="#mblmenu14"
                          href="#mblmenu6"
                          aria-expanded="true"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="sitemap-row sitemap-panel">
                            New to warrants
                            <span
                              className="navmbl-panel-caret glyphicon glyphicon-menu-down"
                              style={{ paddingTop: "4px" }}
                            ></span>
                          </div>
                        </div>
                        <div
                          id="mblmenu14"
                          className="navmbl-panel-collapse-mdlg collapse"
                          style={{ margin: "0px", fontWeight: "400" }}
                        >
                          <div className="sitemap-row sitemap-panel">
                            <Link to="/education/getstarted">
                              How to get started
                            </Link>
                          </div>
                          <div className="sitemap-row sitemap-panel">
                            <Link to="/education/warrantvideos">
                              Educational videos
                            </Link>
                          </div>
                          <div className="sitemap-row sitemap-panel">
                            <Link to="/education/brochure">Brochure</Link>
                          </div>
                          <div className="sitemap-row sitemap-panel">
                            <Link to="/SuccessSpeaks">Success Speaks</Link>
                          </div>
                          <div className="sitemap-row sitemap-panel">
                            <a target="_blank" href="/edu/investormy.html">
                              Investor academy
                            </a>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="dropmenu-row">
                        <div
                          className="navmbl-panel-heading"
                          data-toggle="collapse"
                          data-parent="#navmbl-accordion"
                          data-target="#mblmenu15"
                          href="#mblmenu6"
                          aria-expanded="true"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="sitemap-row sitemap-panel">
                            Existing warrant investors
                            <span
                              className="navmbl-panel-caret glyphicon glyphicon-menu-down"
                              style={{ paddingTop: "4px" }}
                            ></span>
                          </div>
                        </div>
                        <div
                          id="mblmenu15"
                          className="navmbl-panel-collapse-mdlg collapse"
                          style={{ margin: "0px", fontWeight: "400" }}
                        >
                          <div className="sitemap-row sitemap-panel">
                            <a href="/education/marketview">
                              Market View / Commentary
                            </a>
                          </div>
                          <div className="sitemap-row sitemap-panel">
                            <a target="_blank" href="/edu/investormy.html">
                              Investor academy
                            </a>
                          </div>
                        </div>
                      </div> */}
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/LandingPage">
                          New to warrants / website
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/getstarted">
                          How to get started
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/brochure">Brochure</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a href="/education/ShortFormVideos">
                          Educational 1-minute videos
                        </a>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a href="/education/warrantvideos">
                          All videos
                        </a>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/SuccessSpeaks">Success Speaks</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a target="_blank" href="/WarrantsGuidebook">
                          Warrants guidebook
                        </a>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/whymacquarie">Why Macquarie</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/faq">FAQ</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/seminar">Seminar</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/SiMSCI">SiMSCI</Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/education/Indexwarrants">Index Warrants</Link>
                      </div>
                      {/* <div className="sitemap-row sitemap-panel">
                        <Link to="/education/hstech">HSTECH</Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sitemap-row">
              <div className="sitemap-accordion">
                <div className="sitemap-panel">
                  <div className="sitemap-panel-heading">
                    <div className="sitemap-panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent=".sitemap-accordion"
                        href="#sm2"
                      >
                        <span className="glyphicon glyphicon-menu-down" style={{ marginRight: 5 }}></span>
                        Warrant statistics
                      </a>
                    </div>
                  </div>
                  <div id="sm2" className="sitemap-panel-collapse collapse">
                    <div className="sitemap-panel-body">
                      <div className="sitemap-row sitemap-panel">
                        <Link to="/marketdata/toptradedwarrants">
                          Top traded warrants
                        </Link>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a href="/marketdata/topgainloss">
                          Top 10 gainers / losers
                        </a>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a href="/marketdata/turnover">
                          Warrant market turnover
                        </a>
                      </div>
                      <div className="sitemap-row sitemap-panel">
                        <a href="/marketdata/turnoverdist">
                          Turnover distribution
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="hidden-xs hidden-sm" />
          <div className="row">
            <div className="sitemap-othr-xs visible-xs">
              <div className="sitemap-othr-col">
                <div>
                  <a href="/about/importantinformation">
                    Important information
                  </a>
                </div>
                <div>
                  <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                    Privacy policy
                  </a>
                </div>
                <div>
                  <a href="/about/termsofuse">Terms of use</a>
                </div>
              </div>
              <div className="sitemap-othr-col last">
                <div>
                  <a href="/about/contactus">Contact us</a>
                </div>
                <div>
                  <a href="/about/disclaimer">Disclaimer</a>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="col-sm-12 sitemap-othr-sm visible-sm">
              <p>
                <a href="/about/importantinformation">
                  Important information
                </a>{" "}
                |
                <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                  Privacy policy
                </a>{" "}
                |<a href="/about/termsofuse">Terms of use</a> |
                <a href="/about/contactus">Contact us</a> |
                <a href="/about/disclaimer">Disclaimer</a>
              </p>
            </div>
            <div className="col-md-6 sitemap-corp">
              <p>
                © Macquarie Group Limited ABN 94 122 169 279. All rights
                reserved
              </p>
            </div>
            <div className="col-md-6 sitemap-othr hidden-xs hidden-sm">
              <p>
                <a href="/about/importantInformation">
                  Important information
                </a>{" "}
                |
                <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                  Privacy policy
                </a>{" "}
                |<a href="/about/termsofuse">Terms of use</a> |
                <a href="/about/contactus">Contact us</a> |
                <a href="/about/disclaimer">Disclaimer</a>
              </p>
            </div>
          </div>
        </div>
        <div id="xsTest" className="visible-xs"></div>
        <div id="smTest" className="visible-sm"></div>
        <div id="mdTest" className="visible-md"></div>
        <div id="lgTest" className="visible-lg"></div>
      </div>
    );
  }
}
